import { Injectable } from '@angular/core';

import { Routes } from 'src/app/util/routes';
import { HttpClientUrku } from 'src/app/shared/services/http.urku';
import { GridParam } from 'src/app/util/grid.param';

import { Submodulo } from 'src/app/model/submodulo';

@Injectable({
    providedIn: 'root'
})
export class SubmoduloService {

    private routes: Routes = new Routes();
    private url: string;

    constructor(private _http: HttpClientUrku) {
        this.url = this.routes.submodulo;
    }

    list(param?: any | GridParam): Promise<Submodulo[]> {
        return new Promise<Submodulo[]>((resolve, reject) => {
            this._http.get(this.url, param)
            .subscribe((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    estado(submodulo: Submodulo): Promise<Submodulo> {
        if (submodulo.estado) {
            return this.defuse(submodulo);
        } else {
            return this.active(submodulo);
        }
    }

    defuse(submodulo: Submodulo): Promise<Submodulo> {
        return new Promise<Submodulo>((resolve, reject) => {
            this._http.delete(this.url +'/'+ submodulo.id).subscribe((response: Submodulo) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    active(submodulo: Submodulo): Promise<Submodulo> {
        return new Promise<Submodulo>((resolve, reject) => {
            this._http.patch(this.url +'/'+ submodulo.id, submodulo).subscribe((response: Submodulo) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}
